<template>
  <div class="page-not-found under-construction">
    <main>
      <div class="container-xxl px-3">
        <div class="main__wrapper">
          <div class="content__wrapper">
            <div class="text-illustration">
              <div class="text">
                <div class="title">Under Construction</div>
                <p>This page is currently under construction.</p>
                <div class="drop-btn">
                  <!-- <button type="button" class="btn btn-primary">
                    <span>Report Issue</span>
                  </button> -->
                  <!-- <button type="button" class="btn btn-primary">
                    <span>Back to Homepage</span>
                  </button> -->
                </div>
              </div>
              <div class="illustration">
                <img
                  src="./../assets/images/under-construction-illustration.svg"
                  alt="under contruction illustration"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "Under-Construction",
};
</script>

<style scoped>
@import "./../assets/css/under-construction.css";
</style>